import request from './base/request';

export const getLanguageApi = () => {
    return request
      .get('/language')
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.error('err', err);
        throw err;
      });
  };
  
//TO DO
export const updateLanguageApi = (data) => {
    return request.put('/language', data)
}